.modal{
    top: 50%;
    transform: translateY(-50%);
    max-height: 80%;
}
   
.modal::-webkit-scrollbar {
    width: 0.5em;
}

.modal::-webkit-scrollbar-thumb {
background-color: #CED213;
  border-radius: 20px;
}